// Start Bootstrap JS
// ----------------------------------- 

(function(window, document, $, undefined){

  $(function(){

    // POPOVER
    // ----------------------------------- 

    var foundPopover = $('[data-toggle="popover"]');
    try {
        foundPopover.popover();
    }
    catch (err) {
        Bugsnag.notifyException(err, null, {
            variableInfo: {
                foundPopover: foundPopover,
                foundPopoverIsNull: foundPopover == null
            }
        }, "error");
    }

    // TOOLTIP
    // ----------------------------------- 

    $('[data-toggle="tooltip"]').tooltip({
      container: 'body'
    });

    // DROPDOWN INPUTS
    // ----------------------------------- 
    $('.dropdown input').on('click focus', function(event){
      event.stopPropagation();
    });

  });

})(window, document, window.jQuery);
