// Rickshaw
// ----------------------------------- 

(function(window, document, $, undefined){

  $(function(){
    
    if ( typeof Rickshaw === 'undefined' ) return;

    var seriesData = [ [], [], [] ];
    var random = new Rickshaw.Fixtures.RandomData(150);

    for (var i = 0; i < 150; i++) {
      random.addData(seriesData);
    }

    var series1 = [
      {
        color: "#c05020",
        data: seriesData[0],
        name: 'New York'
      }, {
        color: "#30c020",
        data: seriesData[1],
        name: 'London'
      }, {
        color: "#6060c0",
        data: seriesData[2],
        name: 'Tokyo'
      }
    ];

    var graph1 = new Rickshaw.Graph( {
        element: document.querySelector("#rickshaw1"), 
        series:series1,
        renderer: 'area'
    });
     
    graph1.render();


    // Graph 2
    // ----------------------------------- 

    var graph2 = new Rickshaw.Graph( {
      element: document.querySelector("#rickshaw2"),
      renderer: 'area',
      stroke: true,
      series: [ {
        data: [ { x: 0, y: 40 }, { x: 1, y: 49 }, { x: 2, y: 38 }, { x: 3, y: 30 }, { x: 4, y: 32 } ],
        color: '#f05050'
      }, {
        data: [ { x: 0, y: 40 }, { x: 1, y: 49 }, { x: 2, y: 38 }, { x: 3, y: 30 }, { x: 4, y: 32 } ],
        color: '#fad732'
      } ]
    } );

    graph2.render();

    // Graph 3
    // ----------------------------------- 


    var graph3 = new Rickshaw.Graph({
      element: document.querySelector("#rickshaw3"),
      renderer: 'line',
      series: [{
        data: [ { x: 0, y: 40 }, { x: 1, y: 49 }, { x: 2, y: 38 }, { x: 3, y: 30 }, { x: 4, y: 32 } ],
        color: '#7266ba'
      }, {
        data: [ { x: 0, y: 20 }, { x: 1, y: 24 }, { x: 2, y: 19 }, { x: 3, y: 15 }, { x: 4, y: 16 } ],
        color: '#23b7e5'
      }]
    });
    graph3.render();


    // Graph 4
    // ----------------------------------- 


    var graph4 = new Rickshaw.Graph( {
      element: document.querySelector("#rickshaw4"),
      renderer: 'bar',
      series: [ 
        {
          data: [ { x: 0, y: 40 }, { x: 1, y: 49 }, { x: 2, y: 38 }, { x: 3, y: 30 }, { x: 4, y: 32 } ],
          color: '#fad732'
        }, {
          data: [ { x: 0, y: 20 }, { x: 1, y: 24 }, { x: 2, y: 19 }, { x: 3, y: 15 }, { x: 4, y: 16 } ],
          color: '#ff902b'

      } ]
    } );
    graph4.render();


  });

})(window, document, window.jQuery);
