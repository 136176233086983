// MARKDOWN DOCS
// ----------------------------------- 


(function(window, document, $, undefined){

  $(function(){

    $('.flatdoc').each(function(){

      //Flatdoc.run({
        
      //  fetcher: Flatdoc.file('/Documentation/Readme'),

      //  // Setup custom element selectors (markup validates)
      //  root:    '.flatdoc',
      //  menu:    '.flatdoc-menu',
      //  title:   '.flatdoc-title',
      //  content: '.flatdoc-content'

      //});

    });


  });

})(window, document, window.jQuery);
