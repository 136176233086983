// CLASSYLOADER
// ----------------------------------- 

(function(window, document, $, undefined){

  $(function(){

    var $scroller       = $(window),
        inViewFlagClass = 'js-is-in-view'; // a classname to detect when a chart has been triggered after scroll

    $('[data-classyloader]').each(initClassyLoader);
    
    function initClassyLoader() {
    
      var $element = $(this),
          options  = $element.data();
      
      // At lease we need a data-percentage attribute
      if(options) {
        if( options.triggerInView ) {

          $scroller.scroll(function() {
            checkLoaderInVIew($element, options);
          });
          // if the element starts already in view
          checkLoaderInVIew($element, options);
        }
        else
          startLoader($element, options);
      }
    }
    function checkLoaderInVIew(element, options) {
      var offset = -20;
      if( ! element.hasClass(inViewFlagClass) &&
          $.Utils.isInView(element, {topoffset: offset}) ) {
        startLoader(element, options);
      }
    }
    function startLoader(element, options) {
      element.ClassyLoader(options).addClass(inViewFlagClass);
    }

  });

})(window, document, window.jQuery);
